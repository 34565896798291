import api from "../../compenents/ApiController";
import {inlineInputAddHint, inlineInputRemoveHint} from "../../helpers";

const profileSettings = () => {
    const wrapperProfile = document.querySelector('.profile-container')
    const wrapperSubscribe = document.querySelector('.subscription-buyed')
    const wrapperDocuments = document.querySelector('.documents-container')

    if (wrapperProfile) {

        profileSteps();
        setUserInfo();
        setUserInfoPassport();

        if (wrapperProfile.dataset.step && parseInt(wrapperProfile.dataset.step) === 2) {
            delete wrapperProfile.dataset.step
            showSecondStep();
        } else if (wrapperProfile.dataset.step && parseInt(wrapperProfile.dataset.step) === 3) {
            delete wrapperProfile.dataset.step
            showSecondStep();
            hideSecondStep();

        }
    }

    if (wrapperSubscribe) {
        radioSubscribeButton();
    }
    if (wrapperDocuments) {
        dropdownDocuments();
    }
}

const hideSecondStep = () => {
    const threeStepForm = document.querySelector('.three-step');
    const wrapperStep = document.querySelector('.steps-container');
    const threeStepItem = wrapperStep.querySelector('.three');
    const twoStepItem = wrapperStep.querySelector('.two');
    const twoStepForm = document.querySelector('.two-step');


    twoStepItem.classList.remove('current');
    twoStepItem.classList.add('success');
    threeStepItem.classList.add('current');

    twoStepForm.classList.add('hide');
    threeStepForm.classList.remove('hide');
}


const setUserInfo = () => {
    const wrapper = document.querySelector('.two-step.data-user');
    if (!wrapper) return;
    const button = wrapper.querySelector('.two-step-button');


    button.addEventListener('click', async function () {
        const inputs = wrapper.querySelectorAll('input');
        const body = new Map;
        [...inputs].forEach(input => input.value !== '' ? body[input.name] = input.value : null);

        const modal = document.querySelector('.modal__loader');
        if (modal) {
            modal.classList.add('modal__loader-enabled');
        }
        try {
            const res = await api.post({href: '/setUserInfo', body})

            if (modal) {
                modal.classList.remove('modal__loader-enabled');
            }

            if (res === 1) hideSecondStep();

        } catch (e) {
            console.error(e);
        }
    });
}

const setUserInfoPassport = () => {
    const wrapper = document.querySelector('.three-step.passport-data');
    if (!wrapper) return;
    const button = wrapper.querySelector('.three-step-button');


    button.addEventListener('click', async function () {
        const inputs = wrapper.querySelectorAll('input');
        const body = new Map;
        [...inputs].forEach(input => input.value !== '' ? body[input.name] = input.value : null);

        const modal = document.querySelector('.modal__loader');
        if (modal) {
            modal.classList.add('modal__loader-enabled');
        }
        try {

            const passportInputWrapper = wrapper.querySelector('.inline-input__wrapper');

            if (!body.passport || body.passport?.length === 11 ) {
                inlineInputRemoveHint(passportInputWrapper);

                const res = await api.post({href: '/setUserInfo', body})

                if (modal) {
                    modal.classList.remove('modal__loader-enabled');
                }

                if (res === 1) location.reload();
            } else {

                if (modal) {
                    modal.classList.remove('modal__loader-enabled');
                }

                UserInfoPassportValidation(body, passportInputWrapper)
            }

        } catch (e) {
            console.error(e);
        }
    });
}

const UserInfoPassportValidation = (body, inputWrapper) => {
    if (body.passport.length < 11) {
        inlineInputAddHint(inputWrapper, 'Неверный формат паспорта')
    }
}

const showSecondStep = () => {
    const wrapperStep = document.querySelector('.steps-container')

    const firstStepItem = wrapperStep.querySelector('.first')
    const twoStepItem = wrapperStep.querySelector('.two')

    const firstStepForm = document.querySelector('.one-step')
    const twoStepForm = document.querySelector('.two-step')


    firstStepItem.classList.add('success')
    twoStepItem.classList.add('current')

    firstStepForm.classList.add('hide');
    twoStepForm.classList.remove('hide');
}

const profileSteps = () => {
    const wrapperStep = document.querySelector('.steps-container')

    const firstStepItem = wrapperStep.querySelector('.first')
    const twoStepItem = wrapperStep.querySelector('.two')

    const firstStepForm = document.querySelector('.one-step')
    const twoStepForm = document.querySelector('.two-step')

    const continueButtonFirstStep = document.querySelector('.one-step-button')

    continueButtonFirstStep.addEventListener('click', function () {

        firstStepItem.classList.add('success')
        twoStepItem.classList.add('current')

        firstStepForm.classList.add('hide');
        twoStepForm.classList.remove('hide');
    })


}

const radioSubscribeButton = () => {

    const radioSubscribeWallet = document.querySelector('.buy-wallet');
    const radioSubscribeCard = document.querySelector('.buy-card');

    const cardInputContainer = document.querySelector('.container_bank-card')

    radioSubscribeWallet.addEventListener('change', function () {
        cardInputContainer.classList.add('hide');
    })
    radioSubscribeCard.addEventListener('change', function () {
        cardInputContainer.classList.remove('hide');
    })
}

const dropdownDocuments = () => {
    const dropdownClickable = document.querySelector('#docs_download_wrapper')

    dropdownClickable.addEventListener('click', function () {
        if (dropdownClickable.classList.contains('active')) {
            dropdownClickable.classList.remove('active');
        } else {
            dropdownClickable.classList.add('active');
        }

    })
}

export default profileSettings;