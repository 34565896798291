import iMask from "imask";
import {
    CODE_INITIAL_TIMER,
    CODE_MASK
} from "../../variables";
import {addErrorHint, br, isValid, modalLoading, setTimer} from "../../helpers";

let needToStop = false;
let timerJS;
let submitted = false;

const codePage = () => {
  const codeInput = document.querySelector(".code-input");
  if (codeInput) {
    const mask = iMask(codeInput, CODE_MASK);
    codeInput.addEventListener("input", () => {
      if (isValid(codeInput.value, 6)) {
        if (submitted) return;
        modalLoading();
        if (!document.querySelector('.no-code-submit')) document.getElementsByTagName("form")[0]?.submit();
        submitted = true;
      }
    });
    const codeTimerOutput = document.querySelector(".code-timer");
    if (codeTimerOutput) {
      const timeWrapper = document.querySelector(".timer-wrapper");
      const timer = document.querySelector(".timer-text");
        timerJS = setTimer(CODE_INITIAL_TIMER, ".code-timer", () => resendCode(timeWrapper, timer));
    }
    const button = document.getElementById("no_code");
    button?.addEventListener("click", () => {
      const hint = document.querySelector(".code-problem-hint");
      hint?.classList?.toggle("d-none");
    });
  }
};


const resendCode = (wrapper, el) => {
  const link = document.createElement("a");
  link.classList.add("text-2");
  link.classList.add("common__link");
  link.classList.add("code_resend");
  link.appendChild(document.createTextNode("Выслать код повторно"));
  wrapper.replaceChild(link, el);
  const resend = document.querySelector(".code_resend");
  resend?.addEventListener("click", () => {
    const hint = document.createElement("p");
    const hint_timer = document.createElement("span");
    hint.classList.add("text-2");
    hint.classList.add("text-center");
    hint.classList.add("timer-text");
    hint_timer.classList.add("text-2-bold");
    hint_timer.classList.add("code-timer");
    hint.appendChild(document.createTextNode("Сможем отправить код повторно"));
    hint.appendChild(br);
    hint.appendChild(document.createTextNode("через "));
    hint_timer.appendChild(document.createTextNode("59 сек."));
    hint.appendChild(hint_timer);
    wrapper.replaceChild(hint, link);
    setTimer(CODE_INITIAL_TIMER, ".code-timer", () => resendCode(wrapper, hint));
  });
};
export default codePage;
