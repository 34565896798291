const shopPage = () => {
  const wrapper = document.querySelector('.shop');


  if (wrapper) {
      changeSwitch();
  }
}

const changeSwitch = () => {
    const wrapper = document.querySelector('.switch-section .switch');
    if (!wrapper) return;
    else wrapper.classList.remove('d-none');

    const bg = wrapper.querySelector('.switch__bg');
    /*bg.style.right = '41.7245%';*/
    const switchItem = wrapper.querySelectorAll('.switch__item');

    console.log('switchItem', switchItem[0].offsetWidth)


    switchItem.forEach((item, key) => {
        item.addEventListener('click', () => {
            changeSwitchItem(item, key);
        })
    })
}

const changeSwitchItem = (item, key) => {
    const wrapper = document.querySelector('.switch-section .switch');
    if (!wrapper) return;
    const switchItem = wrapper.querySelectorAll('.switch__item');
    const bg = wrapper.querySelector('.switch__bg');

    const cardSection = document.querySelector('.card__section')
    const walletSection = document.querySelector('.wallet__section')

    const switchItemsOnePercent = wrapper.clientWidth / 100;

    const bgRight = switchItem[0].clientWidth / switchItemsOnePercent;
    const bgLeft = switchItem[1].clientWidth / switchItemsOnePercent;

    const bgFreeSpace = 100 - (bgRight + bgLeft);

    const bgRightWithoutSpace = bgRight - bgFreeSpace;
    const bgLeftWithoutSpace = bgLeft - bgFreeSpace;

    if (item.classList.contains('switch__item-active')) return;
    if (key === 0) { //wallet
        switchItem[1].classList.remove('switch__item-active');
        switchItem[0].classList.add('switch__item-active');
        bg.style.left = 0;
        bg.style.right = `${bgLeftWithoutSpace - 1}%`;
        cardSection.classList.remove('d-none')
        walletSection.classList.add('d-none');
    } else { //bsc
        switchItem[0].classList.remove('switch__item-active');
        switchItem[1].classList.add('switch__item-active');
        bg.style.right = 0;
        bg.style.left = `${bgRightWithoutSpace - 1}%`;
        cardSection.classList.add('d-none')
        walletSection.classList.remove('d-none');

    }
}

export default shopPage;