import {
  CARD_CVC,
  CARD_EXP_DATE,
  CARD_NUMBER,
  DOB_MASK,
  INN_MASK,
  MAIL_MASK,
  OGRN_MASK,
  PASSPORT_MASK,
  PHONE_OPTIONS
} from "../variables";
import { Datepicker } from "vanillajs-datepicker";
import iMask from "imask";
import ru from "../../../node_modules/vanillajs-datepicker/js/i18n/locales/ru.js";

Object.assign(Datepicker.locales, ru);

const DATEPICKER_OPTIONS = {
  autohide: true,
  language: "ru",
  maxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18))
};

const mask = () => {
  const inns = document.querySelectorAll(".inline-input.inn"); // ИНН
  const ogrns = document.querySelectorAll(".inline-input.ogrn"); // ОГРН
  const passportInputs = document.querySelectorAll(".passport"); // Серия и Паспорт
  const dobDatepickers = document.querySelectorAll(".datepicker-input"); // Пикеры даты рождения
  const FIOInputs = document.querySelectorAll(".input-name"); // Ввод фио
  const walletInputs = document.querySelectorAll(".wallet-num"); // Кошелёк \ номер телефона
  // const sums = document.querySelectorAll('.summ'); // Итоговая сумма
  const cc_input = document.querySelectorAll(".cc-number");
  const _cards = document.querySelectorAll(".card-num"); // Номер банковской карты
  const cards = [...cc_input, ..._cards];
  const cc_exp = document.querySelectorAll(".cc-exp");
  const cc_cvc = document.querySelectorAll(".cc-csc");
  const emails = document.querySelectorAll(".email-input");

  inns.forEach(el => iMask(el, INN_MASK));
  ogrns.forEach(el => iMask(el, OGRN_MASK));
  passportInputs.forEach(el => iMask(el, PASSPORT_MASK));
  dobDatepickers.forEach(el => {
    new Datepicker(el, DATEPICKER_OPTIONS);
    iMask(el, DOB_MASK);
  });
  FIOInputs.forEach(el => iMask(el, { mask: /^([а-яА-Я\s]+)$/ }));
  walletInputs.forEach(el => iMask(el, PHONE_OPTIONS));
  // sums.forEach(el => iMask(el, SUM_MASK));
  cards.forEach(el => iMask(el, CARD_NUMBER));
  cc_exp.forEach(el => iMask(el, CARD_EXP_DATE));
  cc_cvc.forEach(el => iMask(el, CARD_CVC));
  emails.forEach(el => iMask(el, MAIL_MASK));
};

export default mask;
